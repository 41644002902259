






































import { Component, Vue } from 'vue-property-decorator';
import Cycler from '@/components/Cycler/Cycler.vue';
import CyclerItem from '@/components/Cycler/CyclerItem.vue';

@Component({
  components: {
    CyclerItem,
    Cycler,
  },
})
export default class Hero extends Vue {
  public heroCyclerItems: any[] = [{
    imageSrc: '/assets/images/photos/why-choose-us-to-manage-1.png',
    title: 'PROPERTY MANAGEMENT',
    tagline: 'BUY RENTALS. BUILD WEALTH. STRESS FREE.',
    actions: [{ label: 'TENANT INFO', href: '/tenant-resources' }, { label: 'LANDLORD INFO', href: '/why-choose-us-manage' }],
    wrapperClass: 'green',
  },
  {
    imageSrc: '/assets/images/photos/tenant-resources-addtional.webp',
    title: 'PROPERTY SALES',
    tagline: 'BUY. SELL. CONFIDENCE.',
    actions: [{ label: 'BUYER INFO', href: '/investors-support' }, { label: 'SELLER INFO', href: '/why-choose-us-sales' }],
    wrapperClass: 'green',
  },
  {
    imageSrc: '/assets/images/photos/palm-trees.jpg',
    title: 'HOLIDAY RENTALS',
    tagline: 'RELIABLE. SECURE. 7 DAYS A WEEK.',
    actions: [{ label: 'VIEW LISTINGS', href: 'https://freemans.holidayfuture.com/' }, { label: 'LANDLORD INFO', href: '/why-choose-us-manage' }],
    wrapperClass: 'blue',
  },
  ];

  public currentSlide = this.heroCyclerItems[0];

  handleUpdate(slide: any) {
    this.currentSlide = this.heroCyclerItems[slide.index];
  }
}
